<template>
  <div class="row">
    <div class="col-12">
      <deals-expired-datatable/>
    </div>
  </div>
</template>

<script>
import DealsExpiredDatatable from "@/pages/Deals/datatables/DealsExpiredDatatable";
export default {
  name: "IndexDealsExpired.vue",
  components: {DealsExpiredDatatable}
}
</script>

<style scoped>

</style>
